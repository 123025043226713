<template>
    <v-snackbar
        v-model="snack.open"
        :timeout="3000"
        :color="snack.color"
        dense
    >
        {{ snack.text }}
        <v-btn 
            text 
            @click="snack.open=false"
        >Close</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        snack: Object,
    },
};
</script>