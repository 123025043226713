var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading===true)?_c('div',{staticClass:"loading"},[_vm._v("Loading…")]):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v(" Bulletins "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.bulletins.results,"options":_vm.options,"server-items-length":_vm.bulletins.count,"search":_vm.search,"footer-props":{
        'items-per-page-options': _vm.rowsPerPageItems
      },"loading":_vm.loading,"item-key":"publicid","show-select":"","multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('v-clamp',{attrs:{"autoresize":"","max-lines":1},scopedSlots:_vm._u([{key:"after",fn:function(ref){
      var toggle = ref.toggle;
      var expanded = ref.expanded;
      var clamped = ref.clamped;
return (expanded || clamped)?_c('button',{staticClass:"toggle btn btn-sm",on:{"click":toggle}},[_vm._v(" "+_vm._s(' more')+" ")]):_vm._e()}}],null,true)},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.published",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.published).format('YYYY-MM-DD')))])]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }