<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="grey lighten-2"
      dense
    >
      <span class="title ml-3 mr-5 deep-orange--text"><span class="font-weight-light deep">Patrowl</span>Hears</span>
      <v-spacer />
      <div v-if="!$vuetify.breakpoint.xs">
        <v-btn
          class="deep-orange white--text mr-5"
          @click="clickBuyIt"
        >
          Free 14-Day Trial
          <!-- <v-icon
            right
          >
            mdi-credit-card-outline
          </v-icon> -->
        </v-btn>
        <v-btn href="/#/auth">Login</v-btn>
      </div>
      <div v-else>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on"/>
          </template>
          <v-list>
            <v-list-item @click="clickBuyIt">
              <v-list-item-title >Free 14-Day Trial</v-list-item-title>
            </v-list-item>
            <v-list-item href="/#/auth">
              <v-list-item-title>Login</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
   <v-main>
     <!-- Provides the application the proper gutter -->
     <v-container fluid>
        <router-view></router-view>
     </v-container>
   </v-main>

   <v-footer>
     <v-spacer></v-spacer>
    <div class="caption"><v-chip color="deep-orange font-weight-bold" small label link href="https://patrowl.io">Visit patrowl.io to subscribe online</v-chip> {{app_version.version}} // {{app_version.edition|capitalize}} Edition {{app_version.build}} // &copy; 2020-{{ new Date().getFullYear() }} Patrowl SAS </div>
   </v-footer>
  </v-app>
</template>

<script>

import AppVersion from '../../../VERSION.json';

export default {
  name: 'AppPublicLayout',
  data: () => ({
    app_version: '',
  }),
  mounted() {
    this.app_version = AppVersion;
  },
  methods:{
    clickBuyIt() {
      window.location.href = 'https://patrowlhears.io'
    },
  }
}
</script>
