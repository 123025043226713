<template>
  <div>
    <!-- Exploit Page -->
    <v-card>
      <v-card-title>
        <span>Exploits metadata</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="exploits.results"
        :options.sync="options"
        :server-items-length="exploits.count"
        :search="search"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems
        }"
        :loading="loading"
        class="elevation-4"
        item-key="id"
      >

        <!-- vp at -->
        <template v-slot:[`item.vp`]="{ item }">
          <span>{{getVendorProduct(item.vp)}}</span>
        </template>

        <!-- Updated at -->
        <template v-slot:[`item.updated_at`]="{ item }">
          <span>{{moment(item.updated_at).format('YYYY-MM-DD')}}</span>
        </template>

        <!-- Actions -->
        <template v-slot:[`item.action`]="{ item }">
          <v-icon
            small
            class="mdi mdi-eye"
            color="blue"
            @click="viewVuln(item.vuln_id)"
          >
          </v-icon>
        </template>
      </v-data-table>

      <!-- <v-snackbar v-model="snack.open" :timeout="3000" :color="snack.color">
        {{ snack.text }}
        <v-btn text @click="snack.open = false">Close</v-btn>
      </v-snackbar> --> 
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import _ from 'lodash';

export default {
  name: "exploits",
  data: () => ({
    exploits: [],
    loading: true,
    limit: 20,
    search: '',
    options: {},
    selected: [],
    headers: [
      { text: 'Link', value: 'link' },
      { text: 'Products', value: 'vp', sortable: false },
      { text: 'Last update', value: 'updated_at' },
      { text: 'Actions', value: 'action', sortable: false },
    ],
    rowsPerPageItems: [5, 10, 20, 50, 100],
    /* snack: {
      open: false, 
      color: '',
      text: ''
    } */ 
  }),
  watch: {
    search: _.debounce(function (filter) {
      this.search = filter;
      // reset page count
      this.options.page = 1;  
      this.getDataFromApi();
    }, 500),
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },

  methods: {
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
        } = this.options;
        // let search = this.search.trim().toLowerCase();
        this.limit = itemsPerPage;

        let items = this.getExploits(page, this.limit, sortBy, sortDesc);

        setTimeout(() => {
          resolve({
            items
          });
        }, 300);
      });
      this.loading = false;
    },
    getExploits(page, itemsPerPage, sortBy, sortDesc) {
      let sorted_by = '';
      if (sortBy.length > 0) {
        if (sortDesc[0] === true) {
          sorted_by = '&sorted_by=-' + sortBy;
        } else {
          sorted_by = '&sorted_by=' + sortBy;
        }
      }

      // this.$api.get('/api/exploits?limit='+itemsPerPage+'&page='+page+'&search='+this.search+sorted_by+monitored).then(res => {
      this.$api.get('/api/exploits?limit='+itemsPerPage+'&page='+page+'&search='+this.search+sorted_by).then(res => {
        this.loading = false;
        this.exploits = res.data;
        return this.exploits;
      }).catch(e => {
        this.exploits = [];
        swal.fire({
          title: 'Error',
          text: 'Unable to get exploits',
          showConfirmButton: false,
          showCloseButton: false,
          timer: 3000
        })
      });
    },
    viewVuln(vuln_id) {
      this.$router.push({ 'path': '/vulns/'+vuln_id });
    },
    getVendorProduct(cpes) {
      if (cpes == null) {
        return "";
      }
      var cpe, vp;
      var vp_list = Array();
      for (cpe of cpes) {
        vp = cpe.split(":")[4].replace(/_/g, ' ') + ' ('+ cpe.split(":")[3].replace(/_/g, ' ') +')';
        vp_list.push(vp);
      }
      vp_list = [...new Set(vp_list)];
      return vp_list.join(', ');
    },
    // toggleMonitored(item) {
    //   // save in backend
    //   let data = {
    //     'vendor': item.vendor,
    //     'product': item.product,
    //     'monitored': !item.monitored
    //   };
    //   this.$api.post('/api/monitor/product/toggle', data).then(res => {
    //     this.loading = false;
    //     if (res){
    //       item.monitored = !item.monitored;
    //       // Snack notifications
    //       this.snack = true;
    //       this.snackColor = 'success';
    //       this.snackText = 'Monitoring status successfuly updated.';
    //     } else {
    //       this.snack = true;
    //       this.snackColor = 'error';
    //       this.snackText = 'Unable to change the monitoring status';
    //     }
    //   }).catch(e => {
    //     this.loading = false;
    //     swal.fire({
    //       title: 'Error',
    //       text: 'Unable to change the monitoring status',
    //       showConfirmButton: false,
    //       showCloseButton: false,
    //       timer: 2000
    //     });
    //     return;
    //   });
    // },
  }
};
</script>
