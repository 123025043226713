<template>
    <div>
        Alert Page - TODO
    </div>
</template>

<script>
import router from "../../router";
export default {
  name: "Alerts",
  mounted() {
  },
  methods: {
  }
};
</script>
