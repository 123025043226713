<template>
    <v-card
        :color="getRatingColor(vprating)"
    >
        <v-card-title class="title">{{title}}</v-card-title>
        <v-card-text>
            <v-row justify="center">
                <span class="display-3 font-weight-bold" >{{vprating}}</span>/100
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Colors from "@/common/colors";

export default {
    computed: {
        vprating(){
            return this.score;
        }
    },
    mixins: [
        Colors,
    ],
    props: {
        score: String | Number
    },
    data: () => ({
        title: "Rating Score",
    }),
}
</script>

<style>

</style>
