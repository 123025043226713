<template>
    <v-card
        class="white--text deep-orange--text cardNewsLetter"
    >
        <div v-if="!$vuetify.breakpoint.xs">
            <v-card-title class="text-h4" id="title">
                {{ title }}
            </v-card-title>
        </div>
        <div v-else>
            <v-card-title class="text-h5" id="title">
                {{ title }}
            </v-card-title>
        </div>
        

        <v-card-subtitle
            id="subtitle"
        >
            {{subtitle}}
        </v-card-subtitle>
        
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="email"
                    :rules="emailRules" label="E-mail"
                    required solo
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn 
                medium
                @click="onClickClose"
            >Close</v-btn>
            <v-btn
                class="deep-orange white--text ma-4"
                @click="suscribeNewsLetter"
                medium outlined 
                :disabled="!valid"
            >
                <span class="ma-1">Subscribe</span><v-icon>mdi-email</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        title: "Join our Newsletter",
        subtitle: "",
        email: '',
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
    }),
    methods: {
        suscribeNewsLetter() {
            if (this.$refs.form.validate()) {
                this.$emit("add-news-letter", this.email)
            }
        },
        onClickClose() {
            this.$emit('close-dialog');
        }
    }
}
</script>

<style>
#title {
    justify-content: center;
}
#subtitle {
    justify-content: space-between;
    margin-top: 1em;
    font-size: 1em;
}
#submit {
    margin-top: 0.5em;
    justify-items: center;
}
</style>