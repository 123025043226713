<template>
    <v-card-title primary class="title">
        <v-container class="grey lighten-5">
            <v-row no-gutters>

                <v-col class="pa-2" md="auto">
                    Vuln ID: PH-{{ this.vuln.id }}
                </v-col>

                <v-col class="pa-2" md="auto">
                    <v-icon
                        color="deep-orange"
                        title="Edit Vulnerability"
                        @click="dialog_edit_vuln=true"
                    >mdi-pencil</v-icon>
                    <v-icon
                        color="deep-orange"
                        title="Download as JSON file"
                        @click="downloadVuln('json')"
                    >mdi-download</v-icon>
                    <v-icon
                        color="deep-orange"
                        title="Send vulnerability as email"
                        @click="dialog_send_mail=true"
                    >mdi-email-send-outline</v-icon>
                </v-col>

                <v-col class="pa-2">
                    <v-chip
                        small label outlined
                        :color="this.getColorChipMonitored"
                        @click="toggleMonitored"
                    >{{this.getTextChipMonitored}}</v-chip>
                </v-col>

                <v-col
                    class="pa-2"
                    md="auto"
                    v-if="this.vuln.monitored"
                >
                    <v-select
                        :items="vuln_status"
                        item-text="label"
                        item-value="value"
                        label="Status"
                        v-model="status"
                        dense
                        @change="editStatusVunerability()"
                    />
                </v-col>

            </v-row>
        </v-container>

        <v-dialog
            v-model="dialog_edit_vuln"
            max-width="600px"
            v-if="this.showManageMetadataButtons()"
        >
            <DialogVulnAddEdit
                :vuln="this.vuln"
                action="edit"
            />
        </v-dialog>

        <v-dialog
            v-model="dialog_send_mail"
            max-width="600px"
        >
            <DialogSendEmail
                :vuln_id="this.vuln.id"
                v-on="$listeners"
                @CloseDialog="toggleDialogEmail"
            />
        </v-dialog>

    </v-card-title>
</template>

<script>
import Download from "@/common/download";
import DialogVulnAddEdit from '@/components/vulnerability/vulnerabilityDetails/dialog/DialogVulnAddEdit.vue';
import DialogSendEmail from '@/components/vulnerability/vulnerabilityDetails/dialog/DialogSendEmail.vue';
import swal from 'sweetalert2';

export default {
    mixins: [
        Download
    ],
    data: () =>  ({
        dialog_edit_vuln: false,
        dialog_send_mail: false,
        vuln_status: [
            { label: 'Undefined', value: "undefined" },
            { label: 'Fixed', value: "fixed" },
            { label: 'Not Interesting', value: "not_interesting" },
            { label: 'In Progress', value: "in_progress" },
        ],
        status: "undefined",
    }),
    components: {
        DialogVulnAddEdit,
        DialogSendEmail
    },
    props: {
        vuln: Object
    },
    computed: {
        getColorChipMonitored() {
            return this.vuln.monitored === true ? 'deep-orange' : 'grey'
        },
        getTextChipMonitored() {
            return this.vuln.monitored === true ? 'Monitored' : 'Not Monitored'
        }
    },
    mounted(){
        this.getDataFromApi();

    },
    methods: {
        getDataFromApi() {
            if ( this.vuln.monitored === true ) {
                return new Promise((resolve, reject) => {
                    var status = this.getStatusVulnerability();

                    setTimeout(() => {
                        this.loading = false;
                        resolve({ status });
                    }, 300);
                });
            }
        },
        showManageMetadataButtons() {
            let p = this.$store.getters.profile;
            if (p != null && 'manage_metadata' in p){
                return p.manage_metadata;
            } else {
                return true;
            }
        },
        downloadVuln(format="json") {
            this.$api.get('/api/vulns/'+this.vuln.id+'/export/'+format, {responseType: 'arraybuffer'}).then(res => {
                this.forceFileDownload(res, 'vuln_export_'+this.vuln.id+'.'+format);
                const snack = {
                    open: true,
                    color: "success",
                    text: "Vulnerability details available."
                };
                this.$emit('OpenSnackBar', snack)
            }).catch(e => {
                const snack = {
                    open: true,
                    color: "error",
                    text: "Unable to download vulnerability details."
                };
                this.$emit('OpenSnackBar', snack)
            });
            this.loading = false;
        },
        toggleMonitored() {
            // save in backend
            let data = {
                'monitored': !this.vuln.monitored,
                'vuln_id': this.vuln.id,
                'organization_id': this.$store.getters.orgID
            };
            this.status = "undefined";

            this.$api.put('/api/vulns/'+this.vuln.id+'/toggle', data).then(res => {
                if (res){
                    this.vuln.monitored = !this.vuln.monitored;
                    // Snack notifications
                    const snack = {
                        open: true,
                        color: 'success',
                        text: 'Vulnerability monitoring successfuly updated.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getDataFromApi()
                } else {
                    const snack = {
                        open: true,
                        color: 'error',
                        text: 'Unable to change the vulnerability monitoring status'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to change the vulnerability monitoring status',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
        },
        toggleDialogEmail(value) {
            this.dialog_send_mail = value
        },
        getStatusVulnerability() {
            this.loading = true;
            this.$api.get('/api/vulns/'+this.vuln.id+'/status').then(res => {
                if (res && res.status === 200) {
                    this.status = res.data;
                }
                return this.status;
            }).catch(e => {
                this.counter = "undefined"
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to get counter',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
        editStatusVunerability() {
            // Edit the the status
            this.$api.post('/api/vulns/'+this.vuln.id+'/status/edit', {"status": this.status}).then(res => {
                if (res && res.status === 200) {
                    var snack = {
                        open: true,
                        color: "success",
                        text: "Status successfuly modified."
                    }
                    this.$emit('OpenSnackBar', snack);
                } else {
                    var snack = {
                        open: true,
                        color: "error",
                        text: "Unable to modify the status."
                    }
                    this.$emit('OpenSnackBar', snack);
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to modify the status.',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
        }
    }
}
</script>
