<template>
  <v-container grid-list-md>
    <v-layout row wrap align-center justify-center fill-height>
      <v-flex xs12 sm8 md6 lg6>

        <v-card class="login-card">
          <v-card-title>
            <span class="headline">Login to Patrowl<span class="deep-orange--text font-weight-bold">Hears</span></span>
          </v-card-title>

          <v-spacer/>

          <v-card-text >
            <!-- Login -->
            <v-form v-if="!mfa.open" ref="form-login" v-model="validLogin" lazy-validation>
              <v-container>
                <v-text-field
                  v-model="formLogin.username"
                  :counter="70"
                  label="username"
                  maxlength="70"
                  autocomplete="username"
                  required
                  :rules="rules.username"
                />
                <v-text-field
                  type="password"
                  v-model="formLogin.password"
                  :counter="256"
                  label="password"
                  :rules="rules.password"
                  maxlength="256"
                  autocomplete="current-password"
                  required
                  @keyup.enter.native="login"
                />
                <v-checkbox
                  v-model="enable_firststeps"
                  label="First visit"
                />
              </v-container>
              <v-btn
                class="deep-orange white--text"
                :disabled="!validLogin"
                @click="login"
                block
              >Login</v-btn>

              <v-btn text small
                class="buttonHelper"
                @click="onClickLostPassword"
              >Lost Password ?</v-btn>
            </v-form>

            <v-form v-else ref="form-mfa" v-model="validMFA" lazy-validation>
              <v-text-field
                id='mfa'
                v-model='mfa.number'
                required
                placeholder="ex: 010203"
              />
              <v-row
                align="center"
                justify="space-around"
              >
                <v-btn
                  class="deep-orange white--text ma-1"
                  :disabled="!validMFA"
                  @click="submitMFA"
                >Login</v-btn>
                <v-btn
                  class="deep-orange white--text ma-1"
                  @click="backMFA"
                >Back</v-btn>
              </v-row>
            </v-form>

          </v-card-text>

          <v-divider />

          <v-layout row wrap>
            <v-flex xs12 sm6 md6 lg6 xl6>
              <v-card-title class="deep-orange--text subtitle">Support</v-card-title>
              <v-card-text>
                <v-btn text href="mailto:support@patrowl.io" >
                  <v-icon class="deep-orange--text">mdi-email</v-icon>support@patrowl.io
                </v-btn>
              </v-card-text>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 xl6>
              <v-card-title class="deep-orange--text subtitle">Subscribe online</v-card-title>
              <v-card-text>
                <v-btn text href="https://patrowl.io/products/hears" >
                  <v-icon class="deep-orange--text">mdi-cart</v-icon>patrowl.io
                </v-btn>
              </v-card-text>
            </v-flex>
          </v-layout>

        </v-card>
      </v-flex>
    </v-layout>
    <SnackBar
      :snack='snack'
    />
  </v-container>
</template>

<script>
import swal from 'sweetalert2';
import SnackBar from "@/components/general/snackBar/SnackBar.vue";

export default {
  components: {
    SnackBar
  },
  data: () => ({
    formLogin: {
      username: '',
      password: ''
    },
    enable_firststeps: false,
    mfa: {
      open: false,
      token: null,
      number: '',
    },
    validLogin: true,
    validMFA: true,
    loading: false,
    rules: {
      username: [
        v => !!v || "Username is required",
        v => (v && v.length > 3) || "A username must be more than 3 characters long",
        v => /^[a-zA-Z0-9_\+\-\@\.]+$/.test(v) || "A username can only contain letters, digits and symbols '@.+-_'"
      ],
      password: [
        v => !!v || "Password is required",
        v => (v && v.length > 7) || "The password must be longer than 7 characters"
      ]
    },
    snack: {
      open: false,
      color: '',
      text: '',
    },
  }),
  methods:{
    login() {
      if (this.validLogin){
        this.loading = true;

        // Remove the token
        this.$store.commit('removeToken')

        // Call API to obtain a token
        this.$api.post(this.$store.state.endpoints.obtainJWT, this.formLogin).then(res => {
          if (res.data.token) {
            this.mfa.token = res.data.token;
            this.mfa.open = true;
          } else {
            this.get_Token_User_Org(res.data);
          }
        }).catch(() => {
          this.$store.commit("removeToken");
          swal.fire({
            title: 'Error',
            text: 'Wrong username or password',
            showConfirmButton:false,
            showCloseButton:false,
            timer:3000
          });
        });
      }
    },
    submitMFA() {
      this.$api.post("/users/profile/totp/login/"+this.mfa.number+"/", { eph_token: this.mfa.token }).then((res) => {
        this.get_Token_User_Org(res.data);
      }).catch(() => {
        this.snack = {
          open: true,
          text: 'Wrong 2FA code (or server error).',
          color: 'error'
        }
      });
    },
    routing() {
      if (this.enable_firststeps === true) {
        this.$router.push({name: 'Homepage', query: { firststeps: '1' }});
      } else {
        this.$router.push({name: 'Homepage'});
      }
    },
    get_Token_User_Org(data) {
      this.$store.commit('updateToken', data);
      this.$store.dispatch('getOrg').then(() => {
        this.$store.dispatch('getUser').then(() => {
          this.routing()
        }).catch(() => {
          this.snack = {
            open: true,
            text: 'Unable to get user',
            color: 'error'
          }
        });
      }).catch(() => {
        this.snack = {
          open: true,
          text: 'Unable to get organisation',
          color: 'error'
        }
      });
    },
    backMFA() {
      this.mfa.open = false;
      this.formLogin.username = '';
      this.formLogin.password = '';
    },
    onClickLostPassword() {
      this.$router.push({name: 'LostPassword'});
    }
  }
}
</script>

<style>
#title {
  font-size: 2.5em;
  justify-content: center;
}
.login-card {
  margin-top: 7em;
}
.subtitle {
  font-size: 1em;
}
.btn-support {
  cursor: pointer;
}
.buttonHelper {
  margin-top: 1em;
}
</style>
