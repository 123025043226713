var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md4":""}},[_c('MainCard',{attrs:{"color":"deep-orange","title":"Vulnerabilities","subtitle":"CVE, 0days, ...","stats":_vm.stats.vulns}})],1),_c('v-flex',{attrs:{"md4":""}},[_c('MainCard',{attrs:{"color":"lime","title":"Metadata","subtitle":"Exploits, Threats activities, News, Blog posts","stats":_vm.stats.exploits+_vm.stats.threats}})],1),_c('v-flex',{attrs:{"md4":""}},[_c('MainCard',{attrs:{"color":"teal","title":"Monitored items","subtitle":"Vendors, Products, Vulnerabilites, Bulletins","stats":_vm.stats.monitored}})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Latest monitored vulnerabilities and products (< 30 days)")]),_c('v-card-text',{staticClass:"text-center"},[_c('v-data-table',{attrs:{"headers":_vm.monitored_vulns_headers,"items":_vm.monitored_vulns,"items-per-page":5,"loading":_vm.loading_last_vulns},on:{"click:row":_vm.viewVuln},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-center font-weight-bold",attrs:{"color":_vm.getRatingColor(item.score),"label":""}},[_vm._v(_vm._s(item.score)+"/100")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v("CVSSv2: "+_vm._s(item.cvss))]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v("CVSSv3: "+_vm._s(item.cvss3))])]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"pb-2"},[_c('span',{staticClass:"deep-orange--text font-weight-medium"},[_vm._v(_vm._s(item.cveid))]),_vm._v(" / PH-"+_vm._s(item.id)+" "),_c('v-btn',{attrs:{"color":"deep-orange","icon":"","small":""}},[_c('v-icon',{attrs:{"title":"View details"},on:{"click":function($event){return _vm.viewVuln(item)}}},[_vm._v("mdi-arrow-right-bold-circle-outline")])],1)],1),_c('div',[_vm._v(" "+_vm._s(item.summary)+" ")]),_vm._l((item.products),function(p){return _c('v-chip',{key:p.id,staticClass:"vendor-chip",attrs:{"label":"","small":"","link":""},on:{"click":function($event){return _vm.$router.push({ 'path': '/product/'+p.id });}}},[_vm._v(" "+_vm._s(p.vendor)+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(p.name))])])})],2)]}},{key:"item.metadata",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"label":"","link":"","small":"","color":item.exploit_count>0?'deep-orange':'grey',"title":"Is exploitable?"}},[_vm._v(_vm._s(item.exploit_count))]),_c('v-btn',{attrs:{"color":item.access.vector=='NETWORK'?'deep-orange':'grey',"icon":"","small":""}},[_c('v-icon',{attrs:{"title":"Is exploitable remotely?"}},[_vm._v("mdi-cloud")])],1),_c('v-btn',{attrs:{"color":item.access.authentication=='NONE'?'deep-orange':'grey',"icon":"","small":""}},[_c('v-icon',{attrs:{"title":"Require authentication?"}},[_vm._v("mdi-shield-account")])],1),_c('v-btn',{attrs:{"color":item.is_in_the_news||item.is_in_the_wild?'deep-orange':'grey',"icon":"","small":""}},[_c('v-icon',{attrs:{"title":"Is in the news or exploited in the wild?"}},[_vm._v("mdi-star")])],1)]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD, hh:mm:ss')))])]}}],null,true)})],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md6":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Latest vulnerabilities (Top 20) "),_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":function($event){return _vm.viewVulns()}}},[_vm._v("See all")])],1),_c('v-card-text',{staticClass:"text-center"},[_c('v-data-table',{attrs:{"headers":_vm.vulns_headers,"items":_vm.vulns,"items-per-page":5,"item-class":_vm.rowColor,"loading":_vm.loading_last_vulns},on:{"click:row":_vm.viewVuln},scopedSlots:_vm._u([{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(item.summary)+" ")])]}}],null,true)})],1)],1)],1),_c('v-flex',{attrs:{"md6":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{attrs:{"primary":""}},[_vm._v("Latest exploits metadata (Top 20)")]),_c('v-card-text',{staticClass:"text-center"},[_c('v-data-table',{attrs:{"headers":_vm.exploits_headers,"items":_vm.exploits,"items-per-page":5,"loading":_vm.loading_last_vulns},scopedSlots:_vm._u([{key:"item.relevancy_level",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relevancy_level),function(n){return _c('v-icon',{key:n,staticClass:"mdi mdi-clock-time-six",attrs:{"x-small":"","color":_vm.getColorRelevancyLevel(item.relevancy_level)}})}),_vm._l((5 - item.relevancy_level),function(n){return _c('v-icon',{key:n+1000,staticClass:"mdi mdi-clock-time-six-outline",attrs:{"x-small":"","color":"grey"}})})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-clamp',{attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(item.link)+" ")])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.firststeps_overlay),callback:function ($$v) {_vm.firststeps_overlay=$$v},expression:"firststeps_overlay"}},[_c('first-steps')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }