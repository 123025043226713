import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jwt_decode from 'jwt-decode';
import VuexPersist from 'vuex-persist';

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || process.env.API_BASE_URL || 'http://localhost:3333/'

const vuexPersist = new VuexPersist({
  key: 'patrowlHears',
  storage: window.localStorage
})

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const getDefaultState = () => {
  return {
    user: {
      id: null,
      username: null,
      email: null,
      first_name: null,
      last_name: null,
      current_org: {},
      orgs: [],
      profile: {},
      is_active: false,
      is_org_admin: false,
      is_admin: false,
      is_staff: false,
      is_authenticated: false,
      mfa_enabled: false,
      last_login: null,
    },
    org: {
      id: null,
      name: null,
    },
    isAuthenticated: false,
    tokens: {
      jwt_auth_token: null,
      jwt_refresh_token: null,
    },
    endpoints: {
      obtainJWT: '/api/public/jwt-auth/obtain-token/',
      refreshJWT: '/api/public/jwt-auth/refresh-token/',
    },
  }
}

const state = getDefaultState()

const getters = {
  authToken(state) {
    return state.tokens.jwt_auth_token;
  },
  refreshToken(state) {
    return state.tokens.jwt_refresh_token;
  },
  refreshTokenApi(state) {
    return state.endpoints.refreshJWT;
  },
  orgID(state){
    return state.org.id
  },
  orgName(state) {
    return state.org.name
  },
  user(state) {
    return state.user
  },
  userOrgs(state) {
    return state.user.orgs
  },
  username(state) {
    return state.user.username
  },
  userId(state) {
    return state.user.id
  },
  profile(state) {
    return state.user.profile
  },
  is_admin(state) {
    return state.user.is_admin
  },
  is_org_admin(state) {
    return state.user.is_org_admin
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
    localStorage.clear()
  },
  updateToken(state, payload) {
    state.tokens.jwt_auth_token = payload.access
    state.tokens.jwt_refresh_token = payload.refresh
  },
  removeToken(state) {
    state.tokens.jwt_auth_token = null;
    state.tokens.jwt_refresh_token = null;
  },
  setOrg(state, payload) {
    state.org.id = payload.org_id;
    state.org.name = payload.org_name;
  },
  setUser (state, payload) {
    state.user = payload
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload
  }
}

const actions = {
  resetGlobalState({ commit }) {
    return new Promise((resolve) => {
      commit('resetState')
      resolve();
    })
  },
  refreshToken({ commit, getters }) {
    const payload = {
      refresh: getters.refreshToken
    }
    return new Promise((resolve, reject) => {
      axios
        .post(getters.refreshTokenApi, payload)
        .then((res) => {
          commit('updateToken', res.data)
          resolve(res)
        }, error => {
          reject(error)
        })
    })
  },
  getOrg({ commit, getters}) {
    return new Promise((resolve, reject) => {
      axios
        .get('/users/set-org')
        .then((res) => {
          if (res.data.status === "set") {
            commit('setOrg', res.data)
          }
          resolve(res.data)
        }, error => {
          reject(error)
        })
    })
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/users/api/current')
        .then((res) => {
          let user = {
            'id': res.data.id,
            'username': res.data.username,
            'email': res.data.email,
            'first_name': res.data.first_name,
            'last_name': res.data.last_name,
            'current_org' : res.data.current_org,
            'orgs': res.data.orgs,
            'profile': res.data.profile,
            'is_active': res.data.is_active,
            'is_org_admin': res.data.is_org_admin,
            'is_admin': res.data.is_superuser,
            'is_staff': res.data.is_staff,
            'last_login': res.data.last_login,
          }
          commit('setUser', user)
          commit('setIsAuthenticated', true)
          resolve(res.data)
        }, error => {
          reject(error)
        })
    })
  },
  updateToken({commit}, payload){
    commit('updateToken', payload)
  }
}


const modules = {}

const plugins = [
  vuexPersist.plugin
]

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins
})
