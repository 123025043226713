<template>
    <v-card color="grey lighten-5">

        <v-card-title primary class="title">
            <v-container class="grey lighten-5">
                <v-row no-gutters>
                    <v-col class="pa-2" md="auto">
                        Vuln ID: PH-{{ vuln.id }}
                    </v-col>
                </v-row>
            </v-container>
        </v-card-title>

        <v-card-text>
            <v-list subheader color="grey lighten-5">
                <v-list-item>

                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <span>CVE</span>
                            <v-chip
                                class="mx-2"
                                label link x-small
                                :href="'https://nvd.nist.gov/vuln/detail/'+vuln.cveid"
                                target="_blank"
                            >NVD</v-chip>
                            <v-chip
                                label link x-small
                                :href="'https://cve.mitre.org/cgi-bin/cvename.cgi?name='+vuln.cveid"
                                target="_blank"
                            >MITRE</v-chip>
                        </v-list-item-subtitle>
                        <span>{{vuln.cveid}}</span>
                    </v-list-item-content>
                </v-list-item>

                <CvssListItem
                    :title="title_cvss_v2"
                    :cvss='vuln.cvss'
                    :cvss_vector="vuln.cvss_vector"
                />

                <CvssListItem
                    :title="title_cvss_v3"
                    :cvss='vuln.cvss3'
                    :cvss_vector="vuln.cvss3_vector"
                />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Summary</v-list-item-subtitle>
                        {{vuln.summary}}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Technologies</v-list-item-subtitle>
                          <v-list-item-action-text>
                          <v-chip
                            v-for="p in vuln.products.slice(0, 10)" :key="p.id"
                            class="vendor-chip"
                            label small link
                          >
                            {{ p.vendor }}: <span class="font-weight-bold">{{p.name}}</span>
                          </v-chip>
                        </v-list-item-action-text>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="vuln.cwe_id != ''"
                    :href="'https://cwe.mitre.org/data/definitions/'+vuln.cwe_id.replace('CWE-', '')+'.html'"
                    target="_blank"
                >
                    <v-list-item-content>
                        <v-list-item-subtitle>CWE</v-list-item-subtitle>
                        {{vuln.cwe_id}} - {{vuln.cwe_name}}
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="vuln.cwe_refs != '' && 'MITRE-ATTACK' in vuln.cwe_refs"
                >
                    <v-list-item-content>
                        <v-list-item-subtitle>Mitre ATT&CK techniques</v-list-item-subtitle>
                        <ul id="v-for-cwe_refs">
                            <li v-for="ref in vuln.cwe_refs['MITRE-ATTACK']" link :key="ref['external_id']">
                                <a v-bind:href="ref['url']" target="_blank"> {{ ref['external_id'] }} - {{ ref['description']}}</a>
                            </li>
                        </ul>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="typeof vuln.reflinks !== 'undefined'? vuln.reflinks.length > 0: true"
                >
                    <v-list-item-content>
                        <v-list-item-subtitle>Links</v-list-item-subtitle>
                        <ul id="v-for-reflinks">
                            <li v-for="link in vuln.reflinks" :key='link' link>
                                <a v-bind:href="link" target="_blank"> {{ link }} </a>
                            </li>
                        </ul>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>More Informations</v-list-item-title>
                        <v-list-item-subtitle>All details are available with a <strong>PatrowlHears Pro</strong> access plan online.</v-list-item-subtitle>
                        <v-list-item-action-text>
                            <v-chip
                                class="ma-2"
                                color="deep-orange"
                                text-color="white"
                            >
                                Exploit(s)
                                <v-avatar
                                    right
                                    class="deep-orange darken-4"
                                >{{vuln.exploit_count}}</v-avatar>
                            </v-chip>
                            <v-chip
                                class="ma-2"
                                color="deep-orange"
                                text-color="white"
                            >
                                Threat Activity(ies)
                                <v-avatar
                                    right
                                    class="deep-orange darken-4"
                                >{{vuln.threat_activity_count}}</v-avatar>
                            </v-chip>
                        <!-- </v-list-item-action-text>
                        <v-list-item-action-text> -->
                            <v-chip
                                class="ma-2"
                                color="deep-orange"
                                text-color="white"
                            >
                                CPE(s)
                                <v-avatar
                                    right
                                    class="deep-orange darken-4"
                                >{{vuln.cpe_count}}</v-avatar>
                            </v-chip>
                            <v-chip
                                class="ma-2"
                                color="deep-orange"
                                text-color="white"
                            >
                                Vulnerable Product(s)
                                <v-avatar
                                    right
                                    class="deep-orange darken-4"
                                >{{vuln.product_count}}</v-avatar>
                            </v-chip>
                        </v-list-item-action-text>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-card-text>

    </v-card>
</template>

<script>
import CvssListItem from "@/components/public/vulnerability/CvssListItem.vue";
export default {
    components:{
        CvssListItem
    },
    props:{
        vuln: Object,
    },
    data: () => ({
        title_cvss_v2: 'CVSSv2',
        title_cvss_v3: 'CVSSv3',
        counter: {
            Exploits_count:0,
            threat_activity_count: 0,
            cpe_count: 0,
            vulnerable_products: 0,
        }
    })
}
</script>

<style scoped>
.package-chip {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 3px;
}
/* .v-list-item__content > * {
  flex: 10 1 100%;
} */
</style>
