<template>
    <v-card>
        <v-stepper v-model="step" vertical >
            <v-stepper-step :complete="step > 1" step="1" color="deep-orange">
                Verify your password
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-text-field 
                    v-model="verifyPassword"
                    label="Current Password"
                    :type="showInputPassword ? 'text' : 'password'"
                    :rules="[passwordVerified || 'Invalid Password']"
                    :append-icon="showInputPassword ? 'mdi-eye' : 'mdi-eye-off'" class="mb-1"
                    @click:append="showInputPassword = !showInputPassword"
                    required
                />
                <div class="d-flex flex-column">
                    <v-btn class="deep-orange white--text" elevation="0" @click="checkPassword">
                        Check Password<v-icon dense class="ml-2">mdi-arrow-right</v-icon>
                    </v-btn>
                </div>
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2" color="deep-orange">
                Disable your MFA
            </v-stepper-step>

            <v-stepper-content step="2">
                <div>
                    <v-btn
                        class="mr-2 deep-orange white--text" 
                        elevation="0"
                        @click="disableMFA"
                    >Disable</v-btn>
                    <v-overlay absolute :value="loaderUpdateMFA">
                        <v-progress-circular
                            indeterminate
                            class="deep-orange"
                        ></v-progress-circular>
                    </v-overlay>
                </div>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        step: 1,
        loaderUpdateMFA: false,
        verifyPassword: "",
        showInputPassword: false,
        passwordVerified: true,
    }),
    methods: {
        checkPassword(){
            let formdata = {
                'password': this.verifyPassword
            }

            this.$api.post("users/profile/check_password", formdata)
            .then(res => {
                if (res.status == 200) {
                    this.step = "2"
                } else {
                    this.passwordVerified = false; 
                }
            })
            .catch((err) => {
                this.passwordVerified = false; 
            });
        },
        disableMFA() {
            this.loaderUpdateMFA = true;
            var formData = {
                "mfa_enabled": false
            }
            // Update request
            this.$api.post('/users/profile/update', formData).then(res => {
                if (res && res.status === 200) {
                    // Snack notifications
                    this.$emit('update-snack', {
                        open: true,
                        color: "success",
                        text: "User MFA update"
                    });
                } else {
                    this.$emit('update-snack', {
                        open: true,
                        color: "error",
                        text: 'Unable to change MFA'
                    });
                }
            }).catch(e => {
                this.$emit('update-snack', {
                    open: true, 
                    color: 'error',
                    text: "Unable to change MFA"
                });
            });
            this.loaderUpdateMFA = false;
            this.$emit('close-mfa-dialog')
        }
    },
}
</script>