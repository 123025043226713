<template>
    <v-container fluid>
        <v-col>
            <v-textarea
                outlined
                name="input-comment"
                label="Comment"
                v-model="comment"
                hint="Put comment on this vulnerability"
            />
            <v-btn
                @click="editComment()"
                tile
                color="success"
            >
                <v-icon left>mdi-pencil</v-icon>
                Save
            </v-btn>
        </v-col>
    </v-container>
</template>

<script>
import swal from 'sweetalert2';

export default {
    props:{
        vuln_id: String | Number
    },
    data: () => ({
        comment: ''
    }),
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi(vuln_id) {
            return new Promise((resolve, reject) => {
                let comment = this.getComment(vuln_id);

                setTimeout(() => {
                    this.loading = false;
                    resolve({ comment });
                }, 300);
            });
        },
        getComment() {
            this.loading = true;
            this.$api.get('/api/vulns/'+this.vuln_id+'/comment').then(res => {
                if (res && res.status === 200) {
                    this.comment = res.data;
                }
                return this.comment;
            }).catch(e => {
                this.comment = ""
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to get comment',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false
        },
        editComment() {
            // Edit the commment 
            this.$api.post('/api/vulns/'+this.vuln_id+'/comment/edit', {'comment': this.comment}).then(res => {
                if (res && res.status === 200) {
                    var snack = {
                        open: true, 
                        color: "success",
                        text: 'Comment successfuly modified.'
                    }
                    this.$emit("OpenSnackBar", snack)
                } else {
                    var snack = {
                        open: true, 
                        color: 'error',
                        text: 'Unable to modify the comment.'
                    }
                    this.$emit("OpenSnackBar", snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to modify the comment.',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });   
        }, 
    }
}
</script>