<template>
    <v-card
        color="grey lighten-5"
    >
        <v-card-title class="text-h5 deep-orange--text font-weight-bold" id="title">
            PatrowlHears Pro
        </v-card-title>
        <v-card-subtitle class="text-center font-weight-bold">
          Vulnerability Intelligence Center
        </v-card-subtitle>
        <v-card-text
            class=""
        >
          Monitor vulnerabilities on your technologies.<br/>
          Get advanced access to all exploits, threat activities, API, alerts and search with filters.
        </v-card-text>
        <v-card-text
            class="text-center"
        >
        <v-btn
            elevation="2"
            class="white--text deep-orange"
            @click="onClickButton"
        >Free 14-Day Trial</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
        subtitle: String,
        url: String
    },
    methods: {
        onClickButton() {
            window.location.href = "https://patrowl.io/products/hears"
        }
    }
}
</script>
