<template>
    
    <v-card color="grey lighten-5">
        <v-data-table
            :headers="threat_headers"
            :items="threats"
            class="elevation-4"
            item-key="id"
            multi-sort
            show-expand
            :expanded.sync="expanded"
        >

            <!-- scope -->
            <template v-slot:[`item.scope`]="{ item }">
                <v-icon
                    v-if='item.scope=="public"'
                    color="green"
                >mdi-lock-open-variant</v-icon>
                <v-icon
                    v-else
                    color="orange darken-2"
                >mdi-lock</v-icon>
            </template> 

            <!-- Link --> 
            <template v-slot:[`item.link`]="{ item }">
                <a :href="item.link" target="_blank">{{ item.link }}</a>
            </template>

            <!-- Trust Level --> 
            <template v-slot:[`item.trust_level`]="{ item }">
                {{ item.trust_level | capitalize }}
            </template>

            <!-- TLP --> 
            <template v-slot:[`item.tlp_level`]="{ item }">
                <v-chip 
                    class="ma-2" label 
                    outlined small 
                    :color="getTLPColor(item.tlp_level)"
                >
                    {{ item.tlp_level | capitalize }}
                </v-chip>
            </template>

            <!-- In the Wild --> 
            <template v-slot:[`item.is_in_the_wild`]="{ item }">
                <span>{{transform_true_false(item.is_in_the_wild)}}</span>
            </template>

            <!-- is_in_the_news --> 
            <template v-slot:[`item.is_in_the_news`]="{ item }">
                <span>{{transform_true_false(item.is_in_the_news)}}</span>
            </template>

                <!-- Updated at -->
            <template v-slot:[`item.modified`]="{ item }">
                <span>{{ moment(item.modified).format('YYYY-MM-DD') }}</span>
            </template>


            <!-- Actions --> 
            <template v-slot:[`item.action`]="{ item }">
                <v-icon 
                    small class="mdi mdi-pencil" 
                    color="orange" 
                    @click="loadThreat(item)" 
                    v-if='item.scope!="public"'
                />
                <v-icon 
                    small class="mdi mdi-delete" 
                    color="red" 
                    @click="deleteThreat(item)" 
                    v-if='item.scope!="public"'
                />
            </template>

            <!-- Expand --> 
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">{{ item.notes }}</td>
            </template>

        </v-data-table>

        <template> 
            <v-btn 
                absolute dark 
                fab bottom left 
                color="deep-orange" 
                @click="dialog_threat = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-dialog 
            v-model="dialog_threat"
            max-width="500px"
            v-if="this.showManageMetadataButtons()"
        >
            <DialogThreat 
                :vuln_id="vuln_id"
                :editedItem="this.editedItem"
                v-on="$listeners"
                @addThreat='addThreat' 
                @editThreat='editThreat'
            /> 
        </v-dialog>
    </v-card>
</template>

<script>
import Colors from "@/common/colors";
import moment from 'moment';
import DialogThreat from "./DialogThreat.vue";
import swal from 'sweetalert2';

export default {
    props: {
        vuln_id: String | Number
    },
    components: {
        DialogThreat
    },
    mixins: [
        Colors,
    ],
    data: () => ({
        expanded: [],
        threats: [],
        threat_headers: [
            { text: 'Scope', value: 'scope' },
            { text: 'Link', value: 'link' },
            { text: 'Trust level', value: 'trust_level' },
            { text: 'TLP', value: 'tlp_level', align: 'center' },
            { text: 'Source', value: 'source', align: 'center' },
            { text: 'In the Wild ?', value: 'is_in_the_wild', align: 'center' },
            { text: 'In the News ?', value: 'is_in_the_news', align: 'center' },
            { text: 'Last update', value: 'modified', align: 'center' },
            { text: 'Actions', value: 'action', sortable: false },
        ], 
        editedItem: {},
        dialog_threat: false,
    }),
    mounted() {
        this.getDataFromApi()
    },
    watch: {
        dialog_threat: {
            immediate: true,
            handler() {
                if ( this.dialog_threat === false ){
                    this.editedItem = {}
                }
            }
        }
    },
    methods: {
        getDataFromApi() {
            return new Promise((resolve, reject) => {
                let threats = this.getThreats()

               setTimeout(() => {
                    this.loading = false;
                    resolve({ threats });
                }, 300); 

            });
        },
        getThreats() {
            this.loading = true;
            this.$api.get('/api/vulns/'+this.vuln_id+'/threats').then(res => {
                if (res && res.status === 200) {
                    this.threats = res.data;
                }
                return this.threats;
            }).catch(e => {
                this.threats = [];
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to get related threats activity',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
        loadThreat(item) {
            this.editedItem = item;
            this.dialog_threat = true;
        },
        deleteThreat(item) {
            // save in backend
            this.$api.get('/api/vulns/'+this.vuln_id+'/threats/'+item.id+'/del').then(res => {
                if (res){
                    const snack = {
                        open: true,
                        color: 'success',
                        text: 'Threat activity successfuly deleted.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getThreats()
                } else {
                    const snack = {
                        open: true,
                        color: 'error',
                        text: 'Unable to delete the threat activity'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to delete related threat activity',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.$emit('UpdateCounter', this.vuln_id)
        },
        addThreat(item) {
            // New Threat
            this.$api.post('/api/vulns/'+this.vuln_id+'/threats/add', item).then(res => {
                if (res && res.status === 200) {
                    // Snack notifications
                    const snack = {
                        open: true,
                        color: "success",
                        text: 'Threat activity successfuly saved.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getThreats()          
                    this.$emit('UpdateCounter', this.vuln_id)
                } else {
                    const snack = {
                        open: true,
                        color: "error",
                        text: 'Unable to save the exploit metadata.'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to save related threat activity',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.dialog_threat = false;
        },
        editThreat(item) {
            var data = item
            data['id'] = this.editedItem['id']
            data['maturity'] = ""

            // Edit threat activity
            this.$api.post('/api/vulns/'+this.vuln_id+'/threats/edit', data).then(res => {
                if (res && res.status === 200) {
                    const snack = {
                        open: true,
                        color: "success",
                        text: 'Threat activity successfuly saved.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getThreats()
                } else {
                    const snack = {
                        open: true,
                        color: "error",
                        text: 'Unable to save the threat activity.'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to save related threat activity',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.dialog_threat = false;
        },
        transform_true_false(value){
            if (value === false ){
                return 'No'
            }
            return 'Yes'
        },
        showManageMetadataButtons(){
            let p = this.$store.getters.profile;
            if (p != null && 'manage_metadata' in p){
                return p.manage_metadata;
            } else {
                return true;
            }
        },
    }
}
</script>