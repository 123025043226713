<template>
    <v-card :color="color" >
        <v-card-title primary>{{title}}</v-card-title>
        <v-card-subtitle primary>{{subtitle}}</v-card-subtitle>
        <v-card-text class="display-3 text-center">
        {{stats}}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props:{
        color: 'deep-orange' | 'lime' | 'teal',
        title: String,
        subtitle: String,
        stats: Number
    }
}
</script>