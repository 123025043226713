<template>
    <v-card
        color="grey lighten-5"
        height="100%"
    >
        <v-card-title primary class="title">
            {{this.title}}
        </v-card-title>

        <v-card-text>
            <span>Authentication: <span class="font-weight-bold">{{authentication}}</span></span><br/>
            <span>Complexity: <span class="font-weight-bold">{{complexity}}</span></span><br/>
            <span>Vector: <span class="font-weight-bold">{{vector}}</span></span>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        authentication: String,
        complexity: String,
        vector: String,
    },
    data: () => ({
        title: "Access"
    })
}
</script>