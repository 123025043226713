<template>
    <div v-html="qrcodeImage" />
</template>

<script>
export default {
    data: () => ({
        qrcodeImage:'',
    }),
    mounted() {
        this.getQRcodeSVG();
    },
    methods: {
        getQRcodeSVG() {
            this.$api.get("/users/profile/totp/create").then(res => {
                this.qrcodeImage = res.data;
            }).catch(() => {
                console.log('something does wrong')
            });
            return this.qrcodeImage;
        },
    }

}
</script>
