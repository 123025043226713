<template>
    <v-card> 
        <v-stepper v-model="step" vertical>
            <v-stepper-step :complete="step > 1" step="1"  color="deep-orange">
                Scan your QR code
            </v-stepper-step>
            <v-stepper-content step="1">
                <div>
                    <p><strong>How to enable app based authentication</strong></p>
                    <ol class="mb-0">
                        <li>
                            <span
                            >Download and install an app (such as Google Authenticator) on
                            your mobile device.</span>
                        </li>
                        <li><span>Scan the QR code.</span></li>
                        <li><span>Verify the app generates authentication code.</span></li>
                    </ol>
                </div>
                <QRcodeMFA />
                <v-btn class="deep-orange white--text" elevation="0" @click="step = 2">
                    Next<v-icon dense class="ml-2">mdi-arrow-right</v-icon>
                </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2"  color="deep-orange">
                Test your MFA
            </v-stepper-step>

            <v-stepper-content step="2">
                <div>
                    <v-otp-input
                        v-model="otp"
                        length="6"
                        @finish="onOTPFinish"
                    />
                    <v-overlay absolute :value="loaderVerifyMFA">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        />
                    </v-overlay>
                </div>
                <v-btn @click="step= 1" elevation="0">
                    <v-icon dense class="mr-2 deep-orange white--text">mdi-arrow-left</v-icon>Previous
                </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="step >3" step="3"  color="deep-orange">
                Enable your MFA
            </v-stepper-step>

            <v-stepper-content step="3">
                <div>
                    <v-btn
                        class="mr-2 deep-orange white--text"
                        elevation="0"
                        @click="onClickEnable"
                    >Enable</v-btn>
                    <v-overlay absolute :value="loaderUpdateMFA">
                        <v-progress-circular
                            indeterminate
                            color="deep-orange"
                        />
                    </v-overlay>
                </div>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
import QRcodeMFA from "@/components/mfa/QRcodeMFA.vue";

export default {
    components:{
        QRcodeMFA,
    },
    data: () => ({
        step: 1,
        otp: "",
        loaderVerifyMFA: false,
        loaderUpdateMFA: false,
    }),
    methods: {
        onOTPFinish() {
            this.loaderVerifyMFA = true;
            var formdata = {
                "otp": this.otp
            }
            this.$api.post("/users/profile/totp/verify_auth_totp", formdata).then((res) => {
                if (res.status === 200){
                    this.$emit('update-snack', {
                        open: true,
                        color: "success",
                        text: "OTP Correct"
                    });
                    this.step = 3;
                } else {
                    this.$emit('update-snack', {
                        open: true,
                        color: "error",
                        text: "OTP Incorrect"
                    });
                    this.otp = "";
                }
                this.loaderVerifyMFA = false;
            })
            .catch((err) => {
                this.$emit('update-snack', {
                    open: true,
                    color: "error",
                    text: "OTP Incorrect"
                });
                this.otp = "";
                this.loaderVerifyMFA = false;
            });
        },
        onClickEnable() {
            this.loaderUpdateMFA = true;
            var formData = {
                "mfa_enabled": true
            }

            // Update request
            this.$api.post('/users/profile/update', formData).then(res => {
                if (res && res.status === 200) {
                    // Snack notifications
                    this.$emit('update-snack', {
                        open: true,
                        color: "success",
                        text: "User MFA update"
                    });
                } else {
                    this.$emit('update-snack', {
                        open: true,
                        color: "error",
                        text: 'Unable to change MFA'
                    });
                }
            }).catch(e => {
                this.$emit('update-snack', {
                    open: true, 
                    color: 'error',
                    text: "Unable to change MFA"
                });
            });
            this.loaderUpdateMFA = false;
            this.$emit('close-mfa-dialog')
        }
    }
}
</script>

<style>

</style>