var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Exploits metadata")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.exploits.results,"options":_vm.options,"server-items-length":_vm.exploits.count,"search":_vm.search,"footer-props":{
        'items-per-page-options': _vm.rowsPerPageItems
      },"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.vp",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getVendorProduct(item.vp)))])]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD')))])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mdi mdi-eye",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewVuln(item.vuln_id)}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }