<template>
    <v-card-text>
        <v-list subheader color="grey lighten-5">

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <span>CVE</span>
                        <v-chip
                            class="mx-2"
                            label link x-small
                            :href="'https://nvd.nist.gov/vuln/detail/'+this.vuln.cveid" 
                            target="_blank"
                        >NVD</v-chip>
                        <v-chip
                            label link x-small
                            :href="'https://cve.mitre.org/cgi-bin/cvename.cgi?name='+this.vuln.cveid"
                            target="_blank"
                        >MITRE</v-chip>
                    </v-list-item-subtitle>
                    <span>{{this.vuln.cveid}}</span>
                </v-list-item-content>
            </v-list-item>

            <CvssListItem 
                :title="this.title_cvss_v2"
                :cvss="this.vuln.cvss"
                :cvss_vector="this.vuln.cvss_vector"
            />

            <CvssListItem
                :title="this.title_cvss_v3"
                :cvss="this.vuln.cvss3"
                :cvss_vector="this.vuln.cvss3_vector"
            />

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>
                        <span>EPSS</span>
                    </v-list-item-subtitle>
                    <span>Score: {{this.vuln.epss_score | percent}} - Percentile: {{this.vuln.epss_percentile | percent}}</span>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Summary</v-list-item-subtitle>
                    {{this.vuln.summary}}
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="this.vuln.cwe_id != ''"
                :href="'https://cwe.mitre.org/data/definitions/'+this.vuln.cwe_id.replace('CWE-', '')+'.html'"
                target="_blank"
            >
                <v-list-item-content>
                    <v-list-item-subtitle>CWE</v-list-item-subtitle>
                    {{this.vuln.cwe_id}} - {{this.vuln.cwe_name}}
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="this.vuln.cwe_refs != '' && 'MITRE-ATTACK' in this.vuln.cwe_refs"
            >
                <v-list-item-content>
                    <v-list-item-subtitle>Mitre ATT&CK techniques</v-list-item-subtitle>
                    <ul id="v-for-cwe_refs">
                        <li v-for="ref in this.vuln.cwe_refs['MITRE-ATTACK']" link :key="ref['external_id']">
                            <a v-bind:href="ref['url']" target="_blank"> {{ ref['external_id'] }} - {{ ref['description']}}</a>
                        </li>
                    </ul>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="typeof this.vuln.reflinks !== 'undefined'? this.vuln.reflinks.length > 0: true"
            >
                <v-list-item-content>
                    <v-list-item-subtitle>Links</v-list-item-subtitle>
                    <ul id="v-for-reflinks">
                        <li v-for="link in this.vuln.reflinks" :key='link' link>
                            <a v-bind:href="link" target="_blank"> {{ link }} </a>
                        </li>
                    </ul>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Vulnerable product(s) - {{this.vuln.vulnerable_products.length}} CPE</v-list-item-subtitle>
                    <ul id="v-for-cpe">
                        <li v-for="(value, index) in this.vuln.vulnerable_products" :key="index">
                            {{ value }}
                        </li>
                    </ul>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="this.vuln.vulnerable_packages_versions.length > 0">
                <v-list-item-content>
                    <v-list-item-subtitle>Vulnerable package(s)</v-list-item-subtitle>
                    <ul id="v-for-package">
                        <template v-for="(value, key) in this.vuln.vulnerable_packages_versions">
                        <template v-for="(subvalue, subkey) in value">
                            <li v-for="(subsubvalue, subsubkey) in subvalue" :key="subsubkey">
                                <v-chip
                                    class="package-chip"
                                    label small
                                >{{ key }}:{{ subkey }} </v-chip> 
                                affected: {{ subsubvalue.affected_versions}}, patched: {{ subsubvalue.patched_versions}}

                            </li>
                        </template>
                        </template>
                    </ul>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card-text>

</template>

<script>
import CvssListItem from "@/components/vulnerability/vulnerabilityDetails/cardVulnerability/CvssListItem.vue";

export default {
    components:{
        CvssListItem,
    },
    props: {
        vuln: Object
    },
    data: () => ({
        title_cvss_v2: 'CVSSv2',
        title_cvss_v3: 'CVSSv3',
    }),
    filters: {
        percent: function(dec) {
            if (dec <= 1) {
                return parseFloat(dec * 100).toFixed(3) + "%";
            }
            return '-';
        }
    }
}
</script>

<style>
.package-chip {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 3px;
}
</style>
