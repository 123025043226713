<template>
    <v-card color="grey lighten-5">
        
        <v-card-title primary class="title">
            {{this.title}}
        </v-card-title>

        <v-card-text>
            <v-switch
                v-model="is_exploitable"
                color="deep-orange"
                readonly
                hide-details
                label="Exploitable"
                style="margin-top: 0px;"
                @click="toggleMetric()"
            ></v-switch>

            <v-switch 
                v-model="is_confirmed" 
                color="deep-orange" 
                readonly 
                hide-details 
                label="Confirmed" 
                style="margin-top: 0px;"
            ></v-switch>

            <v-switch 
                v-model="is_in_the_news" 
                color="deep-orange" 
                readonly 
                hide-details 
                label="Relayed in the News" 
                style="margin-top: 0px;"
            ></v-switch>
            
            <v-switch 
                v-model="is_in_the_wild" 
                color="deep-orange" 
                readonly 
                hide-details 
                label="Exploited in the Wild" 
                style="margin-top: 0px;"
            ></v-switch> 

            <v-switch 
                v-model="is_kev" 
                color="deep-orange" 
                readonly 
                hide-details 
                label="Known Exploited (CISA)" 
                style="margin-top: 0px;"
            ></v-switch>

        </v-card-text>

    </v-card>
</template>

<script>
export default {
    data: () => ({
        title: "Metrics"
    }),
    props: {
        is_exploitable: Boolean,
        is_confirmed: Boolean,
        is_in_the_news: Boolean,
        is_in_the_wild: Boolean,
        is_kev: Boolean,
    },
    methods: {
        toggleMetric(){
            const snack = {
                open: true,
                color: 'error',
                text: 'Unable to change the metrics - Insufficient privileges'
            }
            this.$emit('OpenSnackBar', snack)
            
        }
    },
}
</script>