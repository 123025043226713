<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 md5 lg4>
                <v-card class="lost-password-card">
                    <v-card-title>
                        <span class="headline">New password</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="lostPassword" v-model="valid" lazy-validation>
                            <div class="container-form-lost-password">
                                <h3>Reset your password</h3>
                                <v-text-field
                                    type="password"
                                    autocomplete="new-password"
                                    v-model="form.newPassword"
                                    label="New password"
                                    :rules="rules.password"
                                    maxlength="256"
                                    required
                                />
                                <v-text-field
                                    type="password"
                                    autocomplete="new-password"
                                    v-model="form.confirmPassword"
                                    label="Confirm new password"
                                    :rules="rules.password"
                                    maxlength="256"
                                    required
                                />

                            </div>
                            <v-btn
                                class="deep-orange white--text"
                                :disabled="!valid"
                                @click="resetPassword" block
                            >Change my password</v-btn>
                            <v-btn text small
                                class="buttonHelper"
                                @click="onClickReturnAuth"
                            >Return to login form</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-snackbar v-model="snack.open" :timeout="3000" :color="snack.color" dense>
                {{ snack.text }}
                <v-btn text @click="snack.open = false">Close</v-btn>
            </v-snackbar>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "NewPassword",
    data: () => ({
        form : {
            newPassword: '',
            confirmPassword: '',
        },
        rules: {
            password: [
                v => !!v || "Password is required",
                v => (v && v.length > 7) || "The password must be longer than 7 characters"
            ],
        },
        valid: true,
        token: '',
        successChange: false,
        snack: {
            open: false,
            text: "",
            color: ""
        }
    }),
    methods: {
        resetPassword() {
            if (this.form.newPassword != this.form.confirmPassword) {
                this.snack = {
                    open: true,
                    color: "error",
                    text: "Password mismatch."
                }
            } else {
                let formdata = {
                    'token': this.token,
                    'password': this.form.newPassword
                }

                this.$api
                .post('/password_reset/confirm/',formdata)
                .then((res) => {
                    // console.log(res)
                    if (res && res.status === 200){
                        this.snack = {
                            open: true,
                            color: "success",
                            text: "Your password has been successfully changed !"
                        }
                        setTimeout(() => this.$router.push({'name': 'AuthLayout'}), 3000);
                    } else {
                        this.snack = {
                            open: true,
                            color: 'error',
                            text: 'An error occured, please try again or try later.'
                        }
                    }
                }).catch(() => {
                    this.snack = {
                        open: true,
                        color: "error",
                        text: "An error occured, please try again or try later."
                    }
                });

                // Reset form fields
                this.form = {
                    newPassword: '',
                    confirmPassword: ''
                }
            }
        },
        onClickReturnAuth() {
            this.$router.push({name: 'AuthLayout'});
        }
    },
    mounted() {
        this.token = this.$route.query.token
    },
    beforeRouteUpdate(to) {
        this.token = to.token;
    },
}
</script>

<style>

</style>
