var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"left":"","background-color":"white","color":"deep-orange accent-4"}},[_c('v-tab',[_vm._v("By Vendors")]),_c('v-tab',[_vm._v("By Products")]),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"md":"auto"}},[_vm._v(" Vendors ")]),_c('v-col',{staticClass:"pa-2"},[(_vm.only_monitored_vendors)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":_vm.toggleShowOnlyMonitoredVendors}},[_vm._v("Show all")]):_vm._e(),(!_vm.only_monitored_vendors)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":_vm.toggleShowOnlyMonitoredVendors}},[_vm._v("Show monitored only")]):_vm._e()],1),_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_vendors),callback:function ($$v) {_vm.search_vendors=$$v},expression:"search_vendors"}})],1)],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers_vendors,"items":_vm.vendors.results,"options":_vm.options_vendors,"server-items-length":_vm.vendors.count,"search":_vm.search_vendors,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems
          },"loading":_vm.loading,"items-per-page":_vm.rowsPerPage},on:{"update:options":function($event){_vm.options_vendors=$event}},scopedSlots:_vm._u([{key:"item.products_count",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"lightgrey"}},[_vm._v(_vm._s(item.products_count))])]}},{key:"item.monitored",fn:function(ref){
          var item = ref.item;
return [(item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":function($event){return _vm.toggleMonitoredVendor(item)}}},[_vm._v("Yes")]):_vm._e(),(!item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.toggleMonitoredVendor(item)}}},[_vm._v("No")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD')))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mdi mdi-eye",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewVendor(item.id)}}})]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"md":"auto"}},[_vm._v(" Products ")]),_c('v-col',{staticClass:"pa-2"},[(_vm.only_monitored_products)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":_vm.toggleShowOnlyMonitoredProducts}},[_vm._v("Show all")]):_vm._e(),(!_vm.only_monitored_products)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":_vm.toggleShowOnlyMonitoredProducts}},[_vm._v("Show monitored only")]):_vm._e()],1),_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_products),callback:function ($$v) {_vm.search_products=$$v},expression:"search_products"}})],1)],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers_products,"items":_vm.products.results,"options":_vm.options_products,"server-items-length":_vm.products.count,"search":_vm.search_products,"footer-props":{
            'items-per-page-options': _vm.rowsPerPageItems
          },"loading":_vm.loading,"items-per-page":_vm.rowsPerPage},on:{"update:options":function($event){_vm.options_products=$event}},scopedSlots:_vm._u([{key:"item.monitored",fn:function(ref){
          var item = ref.item;
return [(item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":function($event){return _vm.toggleMonitoredProduct(item)}}},[_vm._v("Yes")]):_vm._e(),(!item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.toggleMonitoredProduct(item)}}},[_vm._v("No")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD')))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mdi mdi-eye",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewProduct(item.id)}}})]}}],null,true)})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snack.color},model:{value:(_vm.snack.open),callback:function ($$v) {_vm.$set(_vm.snack, "open", $$v)},expression:"snack.open"}},[_vm._v(" "+_vm._s(_vm.snack.text)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack.open = false}}},[_vm._v("Close")])],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }