<template>
    
    <v-card>

        <v-card-title>
            <span class="headline">
                Send vulnerability by email
            </span>
        </v-card-title>

        <v-card-text>
            <v-form ref="form-vuln-sendmail">
                <v-row>
                    <v-textarea
                        v-model="notification_data.emails"
                        rows="3"
                        label="Emails"
                        hint="Email addresses separated with comma or 1 per line. 10 emails max"
                        prepend-icon="mdi-email"
                    ></v-textarea>
                </v-row>
                <v-btn 
                    color="success" 
                    @click="sendEmailVuln(vuln_id)"
                >
                    Send
                </v-btn>
                <v-btn color="warning" type="reset">
                    Reset
                </v-btn>
            </v-form>
        </v-card-text>

    </v-card>

</template>

<script>
export default {
    props: {
        vuln_id: String | Number
    },
    data: () => ({
        notification_data: {
            'emails': ''
        },
    }),
    methods: {
        sendEmailVuln(vuln_id) {
            this.$api.post('/api/vulns/'+vuln_id+'/export/email', this.notification_data).then(res => {
                const snack = {
                    open: true,
                    color: "success",
                    text: 'Vulnerability details successfuly sent by mail.'
                }
                this.$emit('OpenSnackBar', snack)
            }).catch(e => {
                const snack = {
                    open: true,
                    color: 'error',
                    text: 'Unable to send vulnerability details.'
                }
                this.$emit('OpenSnackBar', snack)
            });
            this.$emit('CloseDialog', false)
        }
    }
}
</script>