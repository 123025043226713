var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-5"}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.exploit_headers,"items":_vm.exploits,"item-key":"id","multi-sort":"","show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.scope",fn:function(ref){
var item = ref.item;
return [(item.scope=="public")?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-lock-open-variant")]):_c('v-icon',{attrs:{"color":"orange darken-2"}},[_vm._v("mdi-lock")])]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])]}},{key:"item.relevancy_level",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relevancy_level),function(n){return _c('v-icon',{key:n,staticClass:"mdi mdi-clock-time-six",attrs:{"x-small":"","color":_vm.getColorRelevancyLevel(item.relevancy_level)}})}),_vm._l((5 - item.relevancy_level),function(n){return _c('v-icon',{key:n+1000,staticClass:"mdi mdi-clock-time-six-outline",attrs:{"x-small":"","color":"grey"}})})]}},{key:"item.trust_level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalize")(item.trust_level))+" ")]}},{key:"item.tlp_level",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","outlined":"","small":"","color":_vm.getTLPColor(item.tlp_level)}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.tlp_level))+" ")])]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.modified).format('YYYY-MM-DD')))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.scope!="public")?_c('v-icon',{staticClass:"mdi mdi-pencil",attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.loadExploit(item)}}}):_vm._e(),(item.scope!="public")?_c('v-icon',{staticClass:"mdi mdi-delete",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteExploit(item)}}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(item.notes))])]}}],null,true)}),[_c('v-btn',{attrs:{"absolute":"","dark":"","fab":"","bottom":"","left":"","color":"deep-orange"},on:{"click":function($event){_vm.dialog_exploit = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],(this.showManageMetadataButtons())?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_exploit),callback:function ($$v) {_vm.dialog_exploit=$$v},expression:"dialog_exploit"}},[_c('DialogExploit',_vm._g({attrs:{"vuln_id":_vm.vuln_id,"editedItem":this.editedItem},on:{"addExploit":_vm.addExploit,"editExploit":_vm.editExploit}},_vm.$listeners))],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }