<template>
    <v-container fluid grid-list-md>        
        <v-layout row wrap>
            <v-flex md9>
                <CardVulnerability
                    v-if="this.vuln.id !== undefined"
                    :vuln="this.vuln"
                    v-on="$listeners"
                />
            </v-flex>

            <!-- SideCards --> 
            <v-flex md3>
                <v-flex>
                    <CardScore 
                        :vuln_id = this.vuln_id
                    />
                </v-flex>
                <v-flex>
                    <CardMetrics
                        :is_exploitable="this.vuln.is_exploitable"
                        :is_confirmed="this.vuln.is_confirmed"
                        :is_in_the_news="this.vuln.is_in_the_news"
                        :is_in_the_wild="this.vuln.is_in_the_wild"
                        :is_kev="this.vuln.is_kev"
                        v-on="$listeners"
                    />
                </v-flex>
                <v-flex>
                    <CardAccess 
                        :authentication="this.vuln.access.authentication"
                        :complexity="this.vuln.access.complexity"
                        :vector="this.vuln.access.vector"
                    />
                </v-flex>
                <v-flex>
                    <CardImpact
                        :confidentiality="this.vuln.impact.confidentiality"
                        :integrity="this.vuln.impact.integrity"
                        :availability="this.vuln.impact.availability"
                    />
                </v-flex>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import CardVulnerability from "@/components/vulnerability/vulnerabilityDetails/cardVulnerability/CardVulnerability.vue";
import CardScore from "@/components/vulnerability/vulnerabilityDetails/CardScore.vue";
import CardMetrics from "@/components/vulnerability/vulnerabilityDetails/CardMetrics.vue";
import CardAccess from "@/components/vulnerability/vulnerabilityDetails/CardAccess.vue";
import CardImpact from "@/components/vulnerability/vulnerabilityDetails/CardImpact.vue";
import swal from 'sweetalert2';

export default {
    props:{
        vuln_id: String | Number
    },
    components: {
        CardVulnerability,
        CardScore, 
        CardMetrics, 
        CardAccess,
        CardImpact
    },
    data: () => ({
        vuln: {
            cwe_id: 'UNKWNOWN',
            cwe_refs: {},
            impact: {confidentiality: '', integrity: '', availability: ''},
            access: {authentication: '', complexity: '', vector: ''},
            reflinks: [],
            vulnerable_products: [],
        },
    }),
    mounted() {
        this.getDataFromApi(this.vuln_id);
    },
    methods:{
        getDataFromApi(vuln_id) {
            return new Promise((resolve, reject) => {
                let vuln = this.getVulnDetails(vuln_id);

                setTimeout(() => {
                this.loading = false;
                    resolve({ vuln });
                }, 300);
            });
        },
        getVulnDetails(vuln_id) {
            this.loading = true;
            this.$api.get('/api/vulns/'+vuln_id+'/').then(res => {
                this.vuln = res.data;
                return this.vuln;
            }).catch(e => {
                this.vuln = {};
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to get vuln details',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
    }
}
</script>
