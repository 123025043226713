var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"md":"auto"}},[_vm._v(" Packages ")]),_c('v-col',{staticClass:"pa-2"},[(this.only_monitored_packages)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":_vm.togglePackageMonitored}},[_vm._v("Show all")]):_vm._e(),(!this.only_monitored_packages)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":_vm.togglePackageMonitored}},[_vm._v("Show monitored only")]):_vm._e()],1),_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search_packages),callback:function ($$v) {_vm.search_packages=$$v},expression:"search_packages"}})],1)],1),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers_packages,"items":_vm.packages.results,"options":_vm.options_packages,"server-items-length":_vm.packages.count,"search":_vm.search_packages,"footer-props":{
        'items-per-page-options': _vm.rowsPerPageItems
      },"loading":_vm.loading,"items-per-page":_vm.rowsPerPage},on:{"update:options":function($event){_vm.options_packages=$event}},scopedSlots:_vm._u([{key:"item.monitored",fn:function(ref){
      var item = ref.item;
return [(item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"deep-orange"},on:{"click":function($event){return _vm.toggleMonitoredPackage(item)}}},[_vm._v("Yes")]):_vm._e(),(!item.monitored)?_c('v-chip',{attrs:{"small":"","label":"","outlined":"","color":"grey"},on:{"click":function($event){return _vm.toggleMonitoredPackage(item)}}},[_vm._v("No")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.updated_at).format('YYYY-MM-DD')))])]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mdi mdi-eye",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.viewPackage(item.id)}}})]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }