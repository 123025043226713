<template>
    <v-card
      color="grey lighten-5"
    >
        <v-card-title class="text-h5 deep-orange--text font-weight-bold" id="title">
            Patrowl
        </v-card-title>
        <v-card-subtitle class="text-center font-weight-bold">
          Be simply faster than attackers !
        </v-card-subtitle>
        <v-card-text
            class=""
        >
        Continuously check your external security posture:<br>
        <ul>
            <li>Zero setup</li>
            <li>SaaS platform</li>
            <li>Actionable recommendations</li>
        </ul>
        </v-card-text>
        <v-card-text
            class=""
        >Get <strong>Patrowl</strong> now, the best External Attack Surface Management (EASM), PenTest-as-a-Service (PTaaS) and Continuous Automated Red Teaming (CART).</v-card-text>
        <v-card-text
            class="text-center"
        >
            <v-btn
                elevation="2"
                class="white--text deep-orange"
                href="https://patrowl.io/"
            >Contact Us</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    methods: {
        onClickButton() {
            window.location.href = 'https://patrowl.io/'
        }
    }
}
</script>
