<template>
    <v-card
        :color="getRatingColor(vprating)"
        v-if="vprating >= 0"
        @click="viewRating()"
    >
        <v-card-title class="title">{{this.title}}</v-card-title>
        <v-card-text>
            <v-row justify="center">
                <span class="display-3 font-weight-bold" >{{vprating}}</span>/100
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import swal from "sweetalert2";
import Colors from "@/common/colors";

export default {
    computed: {
        vprating(){
            return this.ratings.score;
        }
    },
    mixins: [
        Colors,
    ],
    props: {
        vuln_id: String | Number
    },
    data: () => ({
        title: "Rating Score",
        ratings: {
            score: 0,
            cvssV2adj: 0,
        },
        vector: '',
    }),
    mounted () {
        this.getDataFromApi();
    },
    methods: {
        getDataFromApi() {
            return new Promise((resolve, reject) => {
                let ratings = this.getRatings();
                let vector = this.getVulnVector();
                setTimeout(() => {
                    this.loading = false;
                    resolve({ ratings, vector })
                }, 300);
            })
        },
        getRatings() {
            this.loading = true;
            this.$api.get('/api/ratings/calc/'+this.vuln_id).then(res => {
                this.ratings = res.data;
            }).catch(e => {
                this.ratings = {};
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to get vuln ratings',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
        getVulnVector() {
            this.$api.get('/api/ratings/vector/'+this.vuln_id).then(res => {
                this.vector = res.data
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: "Error",
                    text: 'Unable to get Vuln Vector',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
        viewRating() {
            this.$router.push(
                {
                    'name': 'Ratings',
                    'query': {
                        'vector': this.vector
                    }
                }
            );
        }
    }
}
</script>

<style>

</style>
