<template>
    <v-card color="grey lighten-5" height="100%">
        <v-card-title primary class="title">
            {{this.title}}
        </v-card-title>
        <v-card-text>
            <span> Confidentiality: <span class="font-weight-bold">{{confidentiality}}</span><br/></span>
            <span> Integrity: <span class="font-weight-bold">{{integrity}}</span><br/></span>
            <span> Availability: <span class="font-weight-bold">{{availability}}</span></span>
        </v-card-text>
    </v-card>
</template>


<script>
export default {
    data: () => ({
        title: "Impact"
    }),
    props:{
        confidentiality: String,
        integrity: String,
        availability: String,
    }
}
</script>