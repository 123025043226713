<template>
    <v-container grid-list-md>
        <v-layout row wrap align-center justify-center fill-height>
            <v-flex xs12 sm8 md5 lg4>
                <v-card class="lost-password-card">

                    <v-card-title>
                        <span class="headline">Lost Password</span>
                    </v-card-title>

                    <v-spacer />

                    <v-card-text>
                        <v-form ref="lostPassword" v-model="valid" lazy-validation>
                            <div class="container-form-lost-password">
                                <h3>Reset your password</h3>
                                <v-text-field
                                    v-model="lostPassword.email"
                                    label="email"
                                    :rules="rules.email"
                                    autocomplete="email"
                                    required
                                />
                            </div>
                            <v-btn
                                class="deep-orange white--text"
                                :disabled="!valid"
                                @click="sendLinkResetPassword" block
                            >Send me a link</v-btn>
                            <v-btn
                                text small
                                @click="onClickReturnAuth"
                                class="buttonHelper"
                            >Return to login form</v-btn>
                        </v-form>
                    </v-card-text>

                </v-card>
            </v-flex>
            <v-snackbar v-model="snack.open" :timeout="3000" :color="snack.color" dense>
                {{ snack.text }}
                <v-btn text @click="snack.open = false">Close</v-btn>
            </v-snackbar>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    name: "LostPassword",
    data: () => ({
        valid: true,
        lostPassword: {
            email: ''
        },
        rules: {
            email: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        },
        snack: {
            open: false,
            text: "",
            color: ""
        }
    }),
    methods: {
        sendLinkResetPassword() {
            axios.post('/password_reset/', this.lostPassword).then(res => {
                if (res && res.status === 200) {
                    this.snack = {
                        open: true,
                        text: "You will receive an email",
                        color: "success"
                    }
                } else {
                    this.snack = {
                        open: true,
                        text: "Huh? Something goes wrong. Unable to send a link",
                        color: "error"
                    }
                }
            }).catch(() => {
                // console.log(e)
                this.snack = {
                    open: true,
                    text: "Unable to send a link",
                    color: "error"
                }
            });
        },
        onClickReturnAuth() {
            this.$router.push({name: 'AuthLayout'});
        }
    }
}
</script>

<style>
.buttonHelper {
  margin-top: 1em;
}
.container-form-lost-password {
  margin-top: 1em;
  margin-bottom: 2em;
}
</style>
