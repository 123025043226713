<template>
    
    <v-list-item>
        <v-list-item-content class="d-inline">
            <v-list-item-subtitle>{{this.title}}</v-list-item-subtitle>
            <v-chip small label :color="getCVSSColor(this.cvss)">
                {{this.cvss}}
            </v-chip>
            <span> - {{this.cvss_vector}} </span>
            <!-- ({{moment(this.vuln.cvss_time).format('YYYY-MM-DD')}}) -->
        </v-list-item-content>
    </v-list-item>

</template>

<script>
import Colors from "@/common/colors";

export default {
    props:{
        title: String,
        cvss: Number,
        cvss_vector: String,
    },
    mixins: [
        Colors,
    ]
}
</script>