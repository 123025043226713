<template>
    
    <v-card>
        <v-card-title>
            <span class="headline">
                {{ title_form }}
            </span>
        </v-card-title>

        <v-card-text>
            <v-form ref="form">
                <v-container>
                    
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="item.link"
                                label="link"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.trust_level"
                                label="Trust Level"
                                :items="defaultMetadata.trust_level_items"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.tlp_level"
                                label="TLP Level"
                                :items="defaultMetadata.tlp_level_items"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.availability"
                                label="Availability"
                                :items="defaultMetadata.availability_items"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.maturity"
                                label="Maturity"
                                :items="defaultMetadata.maturity_items"
                            />
                        </v-col>
                    </v-row>  

                    <v-row>
                        <v-col>
                            <v-text-field 
                                v-model="item.source"
                                label="Source"
                            />
                            <v-textarea
                                v-model="item.notes"
                                label="Notes"
                                hint="Insert notes about this entry"
                                rows="3"
                            />
                        </v-col>
                    </v-row> 

                    <v-btn 
                        color="success" 
                        @click="saveExploit"
                    >Save</v-btn>

                    <v-btn 
                        color="warning" 
                        @click="resetItemValue"
                    >Reset</v-btn>

                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer>
            </v-spacer>
        </v-card-actions>
            
    </v-card>
</template>

<script>

export default {
    props: {
        vuln_id: String | Number,
        editedItem: Object,
    },
    watch : {
        editedItem:{
            immediate: true,
            handler() {
                this.resetItemValue()
            }
        }
    },
    data: () => ({
        defaultMetadata: {
            link: 'https://',
            trust_level: 'high',
            tlp_level: 'white',
            availability: 'public',
            maturity: 'unknown',
            source: 'manual',
            notes: '', 
            trust_level_items: ['unknown', 'low', 'medium', 'high'],            
            tlp_level_items: ['white', 'green', 'amber', 'red', 'black'],
            availability_items: ['unknown', 'private', 'public'],
            maturity_items: ['unknown', 'unproven', 'poc', 'functional'],
        },
        item: {
            link: '',
            trust_level: '',
            tlp_level: '',
            availability: '',
            maturity: '',
            source: '',
            modified: '',
            notes: '',
        },
    }),
    computed:{
        title_form() {
            return Object.keys(this.editedItem).length === 0 ? 'New Exploit' : 'Edit Exploit'
        }
    },
    methods: {
        saveExploit() {
            this.item.modified = new Date();
            if (  Object.keys(this.editedItem).length === 0 ) {
                this.$emit('addExploit', this.item)
            } else {
                this.$emit('editExploit', this.item)
            }
        },
        setDefaultValue () {
            this.item = {
                link: this.defaultMetadata.link,
                trust_level: this.defaultMetadata.trust_level,
                tlp_level: this.defaultMetadata.tlp_level,
                availability: this.defaultMetadata.availability,
                maturity: this.defaultMetadata.maturity,
                source: this.defaultMetadata.source,
                notes: '',
                modified: '',
            }
        },
        resetItemValue() {
            if (Object.keys(this.editedItem).length === 0) {
                this.setDefaultValue()
            } else {
                this.item = {
                    link: this.editedItem.link,
                    trust_level: this.editedItem.trust_level,
                    tlp_level: this.editedItem.tlp_level,
                    availability: this.editedItem.availability,
                    maturity: this.editedItem.maturity,
                    source: this.editedItem.source,
                    notes: this.editedItem.notes,
                    modified: this.editedItem.modified,
                }
            }
        }
    }
}
</script>