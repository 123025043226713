<template>
    <v-container fluid grid-list-md>
        <v-layout row wrap>

            <!-- SideCards -->
            <v-flex md3>
                <v-flex>
                    <CardScore
                        :score='vuln.score'
                    />
                </v-flex>

                <v-flex>
                    <CardStorePatrowlHears/>
                </v-flex>

                <v-flex>
                    <CardStorePatrowl/>
                </v-flex>

            </v-flex>
            <v-flex md9>
              <v-row no-gutters>
                <CardVulnerability
                    :vuln="vuln"
                />
              </v-row>

              <v-row no-gutters>
                <v-col class="py-2 pr-2" md="4">
                  <CardMetrics
                      :is_exploitable="vuln.is_exploitable"
                      :is_confirmed="vuln.is_confirmed"
                      :is_in_the_news="vuln.is_in_the_news"
                      :is_in_the_wild="vuln.is_in_the_wild"
                      :is_kev="vuln.is_kev"
                  />
                </v-col>
                <v-col class="py-2 pr-2" md="4">
                  <CardAccess
                      :authentication="vuln.access.authentication"
                      :complexity="vuln.access.complexity"
                      :vector="vuln.access.vector"
                  />
                </v-col>
                <v-col class="py-2" md="4">
                  <CardImpact
                      :confidentiality="vuln.impact.confidentiality"
                      :integrity="vuln.impact.integrity"
                      :availability="vuln.impact.availability"
                  />
                </v-col>
              </v-row>
            </v-flex>

        </v-layout>

         <SnackBar
            :snack='snack'
        />
        <v-dialog
            v-model="dialog_news_letter"
            width="500"
            class="d-flex flex-row justify-center align-center"
        >
            <NewsLetter @add-news-letter="addNewsLetter" @close-dialog="closeDialog"/>
        </v-dialog>
    </v-container>
</template>

<script>
import CardScore from "@/components/public/vulnerability/CardScore.vue";
import CardStorePatrowl from "@/components/public/vulnerability/CardStorePatrowl.vue";
import CardStorePatrowlHears from "@/components/public/vulnerability/CardStorePatrowlHears.vue";
import CardMetrics from "@/components/public/vulnerability/CardMetrics.vue";
import CardAccess from "@/components/public/vulnerability/CardAccess.vue";
import CardImpact from "@/components/public/vulnerability/CardImpact.vue";
import CardVulnerability from "@/components/public/vulnerability/CardVulnerability.vue";
import SnackBar from "@/components/general/snackBar/SnackBar.vue";
import NewsLetter from "@/components/public/dialogs/NewsLetter.vue";


export default {
    components: {
        CardScore,
        CardStorePatrowlHears,
        CardStorePatrowl,
        CardMetrics,
        CardAccess,
        CardImpact,
        CardVulnerability,
        SnackBar,
        NewsLetter
    },
    data: () => ({
        vuln_id: '',
        vuln: {
            cwe_id: 'UNKWNOWN',
            cwe_refs: {},
            impact: {confidentiality: '', integrity: '', availability: ''},
            access: {authentication: '', complexity: '', vector: ''},
            reflinks: [],
            vulnerable_products: [],
        },
        dialog_news_letter: true,
        snack: {
            open: false,
            color: '',
            text: '',
        },
    }),
    beforeRouteUpdate(to) {
        this.vuln_id = to.params.vuln_id
    },
    mounted() {
        this.vuln_id = this.$router.currentRoute.params.vuln_id;
        this.getDataFromApi(this.vuln_id)
    },
    methods: {
        getDataFromApi(vuln_id) {
            return new Promise((resolve, reject) => {
                let vuln = this.getVulnDetails(vuln_id);

                setTimeout(() => {
                    this.loading = false;
                    resolve({ vuln });
                }, 300);
            });
        },
        getVulnDetails(vuln_id) {
            this.loading = true;
            this.$api.get('/api/public/vulns/'+vuln_id+'/').then(res => {
                this.vuln = res.data;
                return this.vuln;
            }).catch(e => {
                this.vuln = {};
                this.loading = false;
                this.snack = {
                    open: true,
                    text: 'Unable to get vuln details',
                    color: 'error'
                }
            });
            this.loading = false;
        },
        addNewsLetter(email){
            var form = {
                "email": email
            }
            this.$api.post('/api/alerts/newsletter', form).then(res => {
                this.dialog_news_letter = false
                if (res && res.status === 200 && res.data.status === "success") {
                    this.snack = {
                        open: true,
                        color: 'success',
                        text: 'Subscription proceed. Thank you !'
                    }
                } else {
                    this.snack = {
                        open: true,
                        color: 'error',
                        text: 'Unable to add to our newsletter'
                    }
                }
            }).catch(e => {
                this.snack = {
                    open: true,
                    color: 'error',
                    text: 'Unable to add to our newsletter'
                }
            });
        },
        closeDialog(){
            this.dialog_news_letter = false;
        }
    }
}
</script>

<style>
.vendor-chip {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 3px;
}
.v-chip.v-size--small {
  border-radius: 12px;
  font-size: 12px;
  height: 20px;
}
</style>
