<template>

    <v-card color="grey lighten-5">        
        <v-data-table
            :headers="exploit_headers"
            :items="exploits"
            class="elevation-4"
            item-key="id"
            multi-sort
            show-expand
            :expanded.sync="expanded"
        >

            <!-- scope -->
            <template v-slot:[`item.scope`]="{ item }">
                <v-icon
                    v-if='item.scope=="public"'
                    color="green"
                >mdi-lock-open-variant</v-icon>
                <v-icon
                    v-else
                    color="orange darken-2"
                >mdi-lock</v-icon>
            </template>

            <!-- Link -->
            <template v-slot:[`item.link`]="{ item }">
                <a :href="item.link" target="_blank">{{ item.link }}</a>
            </template>

            <!-- Relevancy level --> 
            <template v-slot:[`item.relevancy_level`]="{ item }">
                <v-icon 
                    x-small class="mdi mdi-clock-time-six" 
                    :color="getColorRelevancyLevel(item.relevancy_level)"
                    v-for="n in item.relevancy_level" 
                    :key='n'
                ></v-icon>
                <v-icon 
                    x-small class="mdi mdi-clock-time-six-outline" 
                    color="grey" 
                    v-for="n in 5 - item.relevancy_level" 
                    :key='n+1000'
                ></v-icon>
            </template>

            <!-- Trust level -->
            <template v-slot:[`item.trust_level`]="{ item }">
                {{ item.trust_level | capitalize }}
            </template>

            <!-- TLP -->
            <template v-slot:[`item.tlp_level`]="{ item }">
                <v-chip 
                    class="ma-2" label 
                    outlined small 
                    :color="getTLPColor(item.tlp_level)"
                >
                    {{ item.tlp_level | capitalize }}
                </v-chip>
            </template>

            <!-- Updated at --> 
            <template v-slot:[`item.modified`]="{ item }">
                <span>{{ moment(item.modified).format('YYYY-MM-DD') }}</span>
            </template>

            <!-- Actions --> 
            <template v-slot:[`item.action`]="{ item }">
                <v-icon
                    small color="orange"
                    class="mdi mdi-pencil"
                    @click="loadExploit(item)"
                    v-if='item.scope!="public"'
                />
                <v-icon 
                    small color="red" 
                    class="mdi mdi-delete"
                    @click="deleteExploit(item)" 
                    v-if='item.scope!="public"'
                ></v-icon>
            </template>

            <!-- Expand --> 
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">{{ item.notes }}</td>
            </template>

        </v-data-table>

        <template>
            <v-btn 
                absolute dark 
                fab bottom left 
                color="deep-orange" 
                @click="dialog_exploit = true"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>

        <v-dialog 
            v-model="dialog_exploit"
            max-width="500px"
            v-if="this.showManageMetadataButtons()"
        >
            <DialogExploit 
                :vuln_id="vuln_id"
                :editedItem="this.editedItem"
                v-on="$listeners"
                @addExploit='addExploit' 
                @editExploit='editExploit' 
            /> 
        </v-dialog>
    </v-card>
</template>

<script>
import Colors from "@/common/colors";
import moment from 'moment';
import DialogExploit from "./DialogExploit.vue";
import swal from 'sweetalert2';

export default {
    props: {
        vuln_id: String | Number
    },
    components: {
        DialogExploit
    },
    mixins: [
        Colors,
    ],
    data: () => ({
        expanded: [],
        exploits: [],
        exploit_headers: [
            { text: 'Scope', value: 'scope' },
            { text: 'Link', value: 'link' },
            { text: 'TLP', value: 'tlp_level', align: 'center' },
            { text: 'Relevancy', value: 'relevancy_level' },
            { text: 'Trust', value: 'trust_level' },
            { text: 'Source', value: 'source', align: 'center' },
            { text: 'Last update', value: 'modified', align: 'center' },
            { text: 'Actions', value: 'action', sortable: false },
            { text: '', value: 'data-table-expand' },
        ],
        editedItem: {}, 
        dialog_exploit: false,
    }),
    mounted() {
        this.getDataFromApi();
    },
    watch: {
        dialog_exploit:{
            immediate: true,
            handler() {
                if ( this.dialog_exploit === false ){
                    this.editedItem = {}
                }
            }
        }
    },
    methods: {
        getDataFromApi() {
            return new Promise((resolve, reject) => {
                let exploits = this.getExploits()

               setTimeout(() => {
                    this.loading = false;
                    resolve({ exploits });
                }, 300); 

            });
        },
        getExploits() {
            this.loading = true;
            this.$api.get('/api/vulns/'+this.vuln_id+'/exploits').then(res => {
                if (res && res.status === 200) {
                    this.exploits = res.data;
                }
                return this.exploits;
            }).catch(e => {
                this.exploits = [];
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to get related exploits',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
            });
            this.loading = false;
        },
        loadExploit(item) {
            this.editedItem = item;
            this.dialog_exploit = true; 
        },
        deleteExploit(item) {
            // save in backend
            this.$api.get('/api/vulns/'+this.vuln_id+'/exploits/'+item.id+'/del').then(res => {
                if (res){
                    const snack = {
                        open: true,
                        color: 'success',
                        text: 'Exploit successfuly deleted.'
                    }
                    this.getExploits()
                    this.$emit('OpenSnackBar', snack)
                } else {
                    const snack = {
                        open: true,
                        color: 'error',
                        text: 'Unable to delete the exploit'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to delete related exploit',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.$emit('UpdateCounter', this.vuln_id)
        },
        addExploit(item) {
            this.$api.post('/api/vulns/'+this.vuln_id+'/exploits/add', item).then(res => {
                if (res && res.status === 200) {
                    const snack = {
                        open: true,
                        color: 'success',
                        text: 'Exploit successfuly saved.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getExploits()          
                    this.$emit('UpdateCounter', this.vuln_id)
                } else {
                    const snack = {
                        open: true,
                        color: 'error',
                        text: 'Unable to save the exploit metadata.'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'Unable to save related exploits',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.dialog_exploit = false
        },
        editExploit(item) {
            var data = item 
            data['id'] = this.editedItem['id']
            // Edit exploit
            this.$api.post('/api/vulns/'+this.vuln_id+'/exploits/edit', data).then(res => {
                if (res && res.status === 200) {
                    const snack = {
                        open: true,
                        color: "success",
                        text: 'Exploit successfuly saved.'
                    }
                    this.$emit('OpenSnackBar', snack)
                    this.getExploits()
                    // Object.assign(this.exploits[this.editedIndex], new_exploit);
                } else {
                    const snack = {
                        open: true,
                        color: "error",
                        text: 'Unable to save the exploit metadata.'
                    }
                    this.$emit('OpenSnackBar', snack)
                }
            }).catch(e => {
                this.loading = false;
                swal.fire({
                    title: 'Error',
                    text: 'unable to save related exploits',
                    showConfirmButton: false,
                    showCloseButton: false,
                    timer: 3000
                });
                return;
            });
            this.dialog_exploit = false
        },
        showManageMetadataButtons(){
            let p = this.$store.getters.profile;
            if (p != null && 'manage_metadata' in p){
                return p.manage_metadata;
            } else {
                return true;
            }
        },
    }  
}
</script>

<style>
</style>