<template>
    
    <v-card color="grey lighten-5">
        <CardHeaderVulnerability
            :vuln="this.vuln"
            v-on="$listeners"
        />
        <CardBodyVulnerability 
            :vuln="this.vuln"
        />
    </v-card>

</template>

<script>
import CardHeaderVulnerability from "@/components/vulnerability/vulnerabilityDetails/cardVulnerability/CardHeaderVulnerability.vue";
import CardBodyVulnerability from "@/components/vulnerability/vulnerabilityDetails/cardVulnerability/CardBodyVulnerability.vue";

export default {
    components:{
        CardHeaderVulnerability,
        CardBodyVulnerability
    },
    props:{
        vuln :Object,
    }
}
</script>