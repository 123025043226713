<template>
    
    <v-card>
        <v-card-title>
            <span class="headline">
                {{title_form}}
            </span>
        </v-card-title>

        <v-card-text>
            <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="item.link"
                                label="link"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                label="Trust Level"
                                v-model="item.trust_level"
                                :items="defaultMetadata.trust_level_items"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.tlp_level"
                                label="TLP Level"
                                :items="defaultMetadata.tlp_level_items"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6" >
                            <v-select
                                v-model="item.is_in_the_news"
                                label="In the News?"
                                :items="defaultMetadata.is_in_the_news_items"
                                item-text="text"
                                item-value="value"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="item.is_in_the_wild"
                                label="In The Wild"
                                :items="defaultMetadata.is_in_the_wild_items"
                            />
                        </v-col>
                    </v-row>
                
                    <v-row>
                        <v-col>
                            <v-text-field 
                                v-model="item.source" 
                                label="Source"
                            />
                            <v-textarea
                                v-model="item.notes"
                                label="Notes"
                                hint="Insert notes about this entry"
                                rows="3"
                            />
                        </v-col>
                    </v-row>
                
                    <v-btn 
                        color="success" 
                        @click="saveThreat"
                    >Save</v-btn>
                    <v-btn 
                        color="warning" 
                        type="reset"
                    >Reset</v-btn>

                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props : {
        vuln_id: String | Number,
        editedItem: Object,
    },
    watch : {
        editedItem:{
            immediate: true,
            handler() {
                this.resetItemValue()
            }
        }
    },
    data: () => ({
        defaultMetadata: {
            link: 'https://',
            trust_level: 'high',
            tlp_level: 'white',
            is_in_the_wild: false,
            is_in_the_news: false,
            source: 'manual',
            notes: '', 
            trust_level_items: ['unknown', 'low', 'medium', 'high'],            
            tlp_level_items: ['white', 'green', 'amber', 'red', 'black'],
            is_in_the_wild_items: [
                { text: 'Yes', value: true},
                { text: 'No', value: false},
            ],
            is_in_the_news_items: [
                { text: 'Yes', value: true},
                { text: 'No', value: false},
            ],
        },
        item: {
            link: '',
            trust_level: '',
            tlp_level: '',
            is_in_the_wild: false,
            is_in_the_news: false,
            source: '',
            notes: '',
            modified: '',
        },
    }),
    computed:{
        title_form() {
            return Object.keys(this.editedItem).length === 0 ? 'New Threat' : 'Edit Threat'
        }
    },
    methods: {
        saveThreat() {
            this.item.modified = new Date();
            if (  Object.keys(this.editedItem).length === 0 ) {
                this.$emit('addThreat', this.item)
            } else {
                this.$emit('editThreat', this.item)
            }
        },
        setDefaultValue () {
            this.item = {
                link: this.defaultMetadata.link,
                trust_level: this.defaultMetadata.trust_level,
                tlp_level: this.defaultMetadata.tlp_level,
                is_in_the_wild: this.defaultMetadata.is_in_the_wild,
                is_in_the_news: this.defaultMetadata.is_in_the_news,
                source: this.defaultMetadata.source,
                notes: '',
                modified: '',
            }
        },
        resetItemValue() {
            if (Object.keys(this.editedItem).length === 0) {
                this.setDefaultValue()
            } else {
                this.item = {
                    link: this.editedItem.link,
                    trust_level: this.editedItem.trust_level,
                    tlp_level: this.editedItem.tlp_level,
                    is_in_the_wild: this.editedItem.is_in_the_wild,
                    is_in_the_news: this.editedItem.is_in_the_news,
                    source: this.editedItem.source,
                    notes: this.editedItem.notes,
                    modified: this.editedItem.modified,
                }
            }
        }
    }
}
</script>