import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import moment from 'moment';
import axios from 'axios';
import './common/filters';
import './common/scores';

import VuetifyConfirm from 'vuetify-confirm';
Vue.use(VuetifyConfirm, { vuetify });

// Axios
const instance = axios.create();

axios.interceptors.request.use(
  config => {
    const token = store.getters.authToken;
    if (token) {
      config.headers.Authorization = 'JWT '+token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error.config;

    // Logout if 401 - Unauthorized
    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
      // store.commit('resetState');
      store.commit('resetState');
      router.push('/auth');
      return
    }

    // this.$store.commit('resetState');
    // Get a new auth token when the previous one expired using refresh token
    if (originalRequest.url !== "/api/public/jwt-auth/refresh-token/" && error.response.status === 401 && error.response.data.code === "token_not_valid" && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Get new access token, put it inside orginal request and send it again
        const rs = await store.dispatch('refreshToken')
        const newAccessToken = rs.data.access;
        originalRequest.headers.Authorization = 'JWT '+ newAccessToken;
        return instance(originalRequest);
      } catch (_error) {
        this.$store.commit('resetState');
        // this.$router.push('/auth');
        return Promise.reject(_error);
      }
    }

    // Logout if refresh token expired too
    if (originalRequest.url === "/api/public/jwt-auth/refresh-token/" && error.response.status === 401 && error.response.data.code === "token_not_valid") {
      store.commit('resetState');
      return
    }

    // // Logout if 401 - Unauthorized
    // if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
    //   // store.commit('resetState');
    //   this.$store.commit('resetState');
    //   this.$router.push('/auth');
    //   return
    // }

    // Return other errors
    if (error.response.status !== 401) {
      return error
    }

    return Promise.reject(error);

  }
)

Vue.prototype.$api = axios;
Vue.prototype.moment = moment;
Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});
